import React from 'react'
import '../../../../node_modules/react-vis/dist/style.css';
import { XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, MarkSeries, LineMarkSeries, DiscreteColorLegend, VerticalBarSeriesCanvas, RectSeries, VerticalRectSeries } from 'react-vis';
import LoadingIcon from '../../../Components/LoadingIcon/LoadingIcon'

const OrdersShippedPerDayChart = ({ ordersShippedPerDayData }) => {
    const kissnerBlue = "#4f81bd"
    const kissnerRed = '#C0504D'
    const kissnerGreen = '#32cd32'

    let convertData = (mode, ordersShippedPerDayData) => {
        let newArray = []
        for (let data of ordersShippedPerDayData) {
            if (mode === 'pickup') {
                let date = new Date(data.Date)
                let newObject = {}
                newObject.x = date
                newObject.y = data.pickups
                newArray.push(newObject)
            } else {
                let date = new Date(data.Date)
                let newObject = {}
                newObject.x = date
                newObject.y = data.total
                newArray.push(newObject)
            }
        }
        return newArray
    }

    let pickupData
    let totalData
    if (ordersShippedPerDayData) {
        pickupData = convertData('pickup', ordersShippedPerDayData)
        totalData = convertData('total', ordersShippedPerDayData)

    }

    if (pickupData && totalData) {
        return (
            <div className='card m-3' style={{ width: "700px" }}>
                <h5 className="card-title text-center">Orders Shipped Per Day</h5>

                {pickupData && totalData ? <>
                    <XYPlot
                        xType="time"
                        width={690}
                        height={300}>
                        <HorizontalGridLines />

                        <XAxis />
                        <YAxis />
                        <LineSeries
                            color={kissnerBlue}
                            data={totalData} />
                        <LineSeries
                            color={kissnerRed}
                            data={pickupData} />
                    </XYPlot></> : null}


            </div>

        )
    } else {
        return (<div className=' d-flex justify-content-center align-items-center'>
            <LoadingIcon />
        </div>)
    }

}

export default OrdersShippedPerDayChart
