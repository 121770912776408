import React, { useState, useEffect } from 'react'
import { Scatter } from 'react-chartjs-2';
import { ScatterController } from 'chart.js';
import getChartDataGenericCall from "../../API/GenericAPICalls/getChartDataGenericCall";
import LoadingIcon from '../../../../Components/LoadingIcon/LoadingIcon';
import DownloadToExcelIcon from "../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon";
import { 
  Chart as ChartJS,
  CategoryScale, // x-axis, required for continuous unique data 'types of fruit'
  LinearScale, // y-axis
  PointElement, // adds the dots in the lines
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

const Scatter01 = ({ config, keynumber, chartId, subuserId, usercode, filters, handleShow, handleClose, setSelectedChartIdDownload }) => {
  const [data, setData] = useState(null)
  const [chartConfiguration, setChartConfiguration] = useState(null)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState("")
  const [Title, setTitle] = useState("")
  const [cardStyle, setCardStyle] = useState({ height: 400, width: 850 })
  const [backgroundColor, setBackgroundColor] = useState("#4dc9f6")
  const [options, setOptions] = useState({})

  useEffect(() => {
    startUp()
    console.log('Scatter01 config', config)

  }, [config])

  useEffect(() => {
    deriveChartVarsFromChartCustomization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartConfiguration])


  // ChartJS.register is a method provided by ChartJS that allows you to register custom components for use with charts.
  ChartJS.register(
    ScatterController,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
  );

  const startUp = async () => {
    let validated = await validateAndSetConfig()
    if (validated) {
      let url = config.url
      let dataAPIResult = await getChartDataGenericCall(url, chartId, usercode, filters)
      setData(dataAPIResult.data)

    }
  }

  const COLORS = [
    "#4dc9f6",
    "#f67019",
    "#f53794",
    "#537bc4",
    "#acc236",
    "#166a8f",
    "#00a950",
    "#58595b",
    "#8549ba"
  ];

  const validateAndSetConfig = async () => {
    if (!config) {
      setError(true)
      setErrorText("No configuration passed to Scatter01")
      return false
    }
    let cfg = await JSON.parse(config.chartCustomization)
    setChartConfiguration(cfg)
    return true
  }

  const deriveChartVarsFromChartCustomization = async () => {
    if (chartConfiguration) {
       console.log("chartConfiguration For Scatter01:", chartConfiguration)
      if (chartConfiguration?.Title) {
        setTitle(chartConfiguration.Title.Title)
      }
      if (chartConfiguration?.cardStyle) {
        setCardStyle(chartConfiguration.cardStyle)
      }
      if (chartConfiguration?.backgroundColor) {
        setBackgroundColor(chartConfiguration.backgroundColor)
      }
      if (chartConfiguration?.options) {
        setOptions(chartConfiguration.options)
      }
    }
  }

  if (data && chartConfiguration && !error) {
     console.log('scatterStuffAllData:', data)
  
    let filteredData = data.filter(item => item.y !== null);
    let dataPoints = filteredData.map(item => ({x: item.x, y: item.y}));
    let datasets = [{ label: chartConfiguration.label, data: dataPoints, backgroundColor: backgroundColor }];

     // console.log(filteredData)  its working to filter out the null values data to datasets is now working with lowercase y
    // let datasets = [{ label: chartConfiguration.label, data: filteredData.map(item => item.y), backgroundColor: backgroundColor }];
    // console.log('scatter01:',{ datasets: [{ data: data.map(item => item.y) }] })

    let filteredlabels = data.filter(item => item.x !== null)
    let labels = filteredlabels.map(item => item.x) 
    //console.log('this is scatter01 datasets', datasets)
    // console.log('this is scatter labels:', labels)
  
    // Also need the x dimension to be the label
    console.log('ScatterThismofo!:',
    {labels, datasets})
    return (
      
      <div className="card d-inline-block card-chart m-1 p-3 " style={cardStyle} key={keynumber}>
        <h5 className="card-Title text-center">{Title}</h5>
        {
            config?.download && config?.download === "true" ?
                <div className="d-flex flex-row-reverse"><DownloadToExcelIcon config={config} handleShow={handleShow} handleClose={handleClose} setSelectedChartIdDownload={setSelectedChartIdDownload} /></div>
                : null
        }
        <div>
          <Scatter
            height={300}
            options={options}
            data={
                 {
                   datasets: datasets, 
                   labels: labels     
                 }      
                }
          />
        </div>
      </div>
    )
  } else if (error) {
    return (
      <div className="card d-inline-block card-chart m-3 p-2">
        <div className=" d-flex justify-content-center align-items-center">
          Error: {errorText}
        </div>
      </div>
    )
  } else {
    return (
      <div className="card d-inline-block card-chart m-3 p-2">
        <div className=" d-flex justify-content-center align-items-center">
          <LoadingIcon />
        </div>
      </div>
    )
  }
}

export default Scatter01