import React from 'react'
import LoadingIcon from '../../../Components/LoadingIcon/LoadingIcon'

const NonOptimalShipmentsTable = ({ nonOptimalShipmentsListData }) => {

    if (nonOptimalShipmentsListData) {
        return (
            <div className="">
                <table className="table table-sm table-bordered x-small-font" >
                    <thead>
                        <tr className="text-center">
                            <th>Order</th>
                            <th>Origin</th>
                            <th>Optimal WH</th>
                            <th>Destination</th>
                            <th>Excess Miles</th>
                            <th>Notes</th>
                            <th>Est. Rate Difference</th>
                        </tr>
                    </thead>
                    <tbody>
                        {nonOptimalShipmentsListData.map((shipment, index) => {
                            return (
                                <tr>
                                    <td>{shipment.usercontrol}</td>
                                    <td>{shipment.shipcity}</td>
                                    <td>{shipment.FreightOptimal}</td>
                                    <td>{shipment.destination}</td>
                                    <td>{shipment.excessMiles}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
        )
    } else {
        return (<div className='m-3 width500px d-flex justify-content-center align-items-center'>
            <LoadingIcon />
        </div>)
    }
}

export default NonOptimalShipmentsTable
