// this is a custom Line Chart that was originally developed for a Kwiktrip. It was intended to be a bar and line chart but 
// that is not possible within chartjs. It has instead been changed into a line chart with the ability to plot a trendline. 

import React, { useState, useEffect } from 'react'
import { Bar, Line } from 'react-chartjs-2';
import getChartDataGenericCall from '../../API/GenericAPICalls/getChartDataGenericCall';
import LoadingIcon from '../../../../Components/LoadingIcon/LoadingIcon';
import DownloadToExcelIcon from '../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon';


const BarAndLine01 = ({ config, keynumber, chartId, subuserId, usercode, filters, handleShow, handleClose, setSelectedChartIdDownload }) => {
    const [data, setData] = useState(null)
    const [chartConfiguration, setChartConfiguration] = useState(null)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [hoveredData, setHoveredData] = useState(null);
    const [title, setTitle] = useState("")
    const [cardStyle, setCardStyle] = useState({ height: 350, width: 350 })
    const [chartWidth, setChartWidth] = useState(300)
    const [chartHeight, setChartHeight] = useState(300)
    const [radialChartDivStyle, setRadialChartDivStyle] = useState({ display: 'flex', justifyContent: 'center' })
    const [legend, setLegend] = useState(null)
    const [labelsRadiusMultiplier, setLabelsRadiusMultiplier] = useState(1)
    const [labelsStyle, setLabelsStyle] = useState({})
    const [trendLineData, setTrendLineData] = useState(null); // set trendline state to null



    useEffect(() => {
        startUp()
    }, [config])

    useEffect(() => {
        deriveChartVarsFromChartCustomization()
    }, [chartConfiguration])

    const startUp = async () => {
        let validated = await validateAndSetConfig()
        if (validated) {
            let url = config.url
            let dataAPIResult = await getChartDataGenericCall(url, chartId, usercode, filters)
            setData(dataAPIResult.data)

        }
    }

    const COLORS = [
        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba',
        '#ff6384',
        '#ffcd56',
        '#36a2eb',
        '#ff9f40',
        '#cc65fe',
        '#ffce56',
        '#3dc9dc',
        '#8e5ea2',
        '#ffc168'
      ];


    const validateAndSetConfig = async () => {
        if (!config) {
            setError(true)
            setErrorText('No configuration passed to BarAndLine01 component')
            return false
        }
        let cfg = await JSON.parse(config.chartCustomization)
        setChartConfiguration(cfg)
        return true
    }

    const deriveChartVarsFromChartCustomization = async () => {
        if (chartConfiguration) {
            if (chartConfiguration?.title) {
                setTitle(chartConfiguration.title.title)
            }
            if (chartConfiguration?.cardStyle) {
                setCardStyle(chartConfiguration.cardStyle)
            }
            if (chartConfiguration?.chartSize?.chartWidth) {
                setChartWidth(chartConfiguration.chartSize.chartWidth)
            }
            if (chartConfiguration?.chartSize?.chartHeight) {
                setChartHeight(chartConfiguration.chartSize.chartHeight)
            }
            if (chartConfiguration?.discreteColorLegend) {
                setLegend(chartConfiguration.discreteColorLegend)
            }
            if (chartConfiguration?.labelsRadiusMultiplier) {
                setLabelsRadiusMultiplier(chartConfiguration.labelsRadiusMultiplier)
            }
            if (chartConfiguration?.labelsStyle) {
                setLabelsStyle(chartConfiguration.labelsStyle)
            }
        }
    }

        // this function is so that the data within the line will be displayed when you hover over it. 
    const handleHover = (event, activeElements) => {
        if (activeElements.length > 0) {
          const selectedIndex = activeElements[0].index;
          const hoveredItem = data[selectedIndex];
          setHoveredData(hoveredItem);
        }
      };
      
      const handleLeave = () => {
        setHoveredData(null);
      };

      // this function creates a trendline if data is present
      const calculateTrendLine = () => {
        if (data) {
          const cpmValues = data.map(item => item.y);
          const n = cpmValues.length;
      
          // Calculate sum of x values (indices)
          const sumX = cpmValues.reduce((sum, _, index) => sum + index, 0);
      
          // Calculate sum of y values (CPM)
          const sumY = cpmValues.reduce((sum, value) => sum + value, 0);
      
          // Calculate sum of xy
          const sumXY = cpmValues.reduce((sum, value, index) => sum + (index * value), 0);
      
          // Calculate sum of x^2
          const sumXSquare = cpmValues.reduce((sum, _, index) => sum + (index * index), 0);
      
          // Calculate slope (m) and y-intercept (b) of the trend line equation (y = mx + b)
          const slope = (n * sumXY - sumX * sumY) / (n * sumXSquare - sumX * sumX);
          const yIntercept = (sumY - slope * sumX) / n;
      
          // Generate trend line data based on the slope and y-intercept
          const trendLine = cpmValues.map((_, index) => slope * index + yIntercept);
      
          setTrendLineData(trendLine);
        }
      };
      
      useEffect(() => {
        calculateTrendLine();
      }, [data]);
      

    const myData = [{ label: 'Super Custom label', angle: 1 }, { angle: 2, label: 'Super Custom label', subLabel: 'With annotation' }, { angle: 5, label: 'Alt Label' }, { angle: 3 }, { angle: 5, subLabel: 'Sub Label only' }]

    if (data && chartConfiguration && !error) {
         console.log({ datasets: [{ data: data.map(item => item.y) }] })
        return (
            <div className='card d-inline-block m-1 p-3 ' style={cardStyle} key={keynumber}>
                <h5 className="card-title text-center">{title}</h5>
                <div style={radialChartDivStyle}>
                    <Line height={200} options={{ maintainAspectRatio: true, legend: false, onHover: handleHover,
    onLeave: handleLeave }} data={{ labels: data.map(item => item.x), 
                        datasets: [{ label: 'Cost Per Mile', data: data.map(item => item.y), backgroundColor: data.map((_, i) => COLORS[i]) }, {
                            label: 'Trend Line',
                            data: trendLineData,  // this is where you pass in the trendlineData if you want it in the chart 
                            borderColor: 'red',
                            fill: false
                          }/*{label: 'Volume', data: data.map(item => item.z), backgroundColor: data.map((_, i) => COLORS[i+1])}*/] }} />
                </div>
                {
                    config?.download && config?.download === "true" ?
                        <div className="d-flex flex-row-reverse"><DownloadToExcelIcon config={config} handleShow={handleShow} handleClose={handleClose} setSelectedChartIdDownload={setSelectedChartIdDownload} /></div>
                        : null
                }
            </div>
        )
    } else if (error) {
        return (
            <div className='card d-inline-block m-3 p-2'>
                <div className=' d-flex justify-content-center align-items-center'>
                    Error: {errorText}
                </div>
            </div>
        )
    } else {
        return (
            <div className='card d-inline-block m-3 p-2'>
                <div className=' d-flex justify-content-center align-items-center'>
                    <LoadingIcon />
                </div>
            </div>
        )
    }
}

export default BarAndLine01
