import React, { useState, useEffect } from "react";
import getChartDataGenericCall from "../../API/GenericAPICalls/getChartDataGenericCall";
import LoadingIcon from "../../../../Components/LoadingIcon/LoadingIcon";
import TruckIcon from "../../../../Components/LoadingIcon/TruckIcon/TruckIcon.js";

import DownloadToExcelIcon from "../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon";

/* This component is designed to pass in and display a three data point for 3 years. You must have a 'year' returned as one of the columsn in the query
and have the data called 'x'. It should then map through and match up the year to the data. Technically, you can pass in anything from 1 to 5 years likely 
but will have to adjust the height of the canvas to fit and may throw off asthetics within graphs. 3 fits nicely within the standard 400 height dimension  */

const NumberDisplay = ({ data, title, cardStyle }) => {
  return (
    <div className="card card-chart d-inline-block m-1 p-3" style={cardStyle}>
      <h5 className="card-title text-center">{title}</h5>
      <div className="text-left">
        {Object.entries(data).map(([key, value]) => (
          <div
            key={key}
            className="display-number"
            style={{
              fontSize: "50px",
              marginBottom: "20px",
              display: "inline-block",
              border: "3px solid #e8e8e8",
              paddingLeft: "15px",
              width: "100%"
            }}
          >
            <span style={{ marginRight: "20px" }}>{/* <TruckIcon /> */}</span>
            {value}
          </div>
        ))}
      </div>
    </div>
  );
};


const BasicSeriesThree = ({
  config,
  keynumber,
  chartId,
  subuserId,
  usercode,
  filters,
  handleShow,
  handleClose,
  setSelectedChartIdDownload,
}) => {
  const [data, setData] = useState(null);
  const [chartConfiguration, setChartConfiguration] = useState(null);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [title, setTitle] = useState("");
  const [chartWidth, setChartWidth] = useState(550);
  const [chartHeight, setChartHeight] = useState(200);
  const [legend, setLegend] = useState(null);
  const [labelsRadiusMultiplier, setLabelsRadiusMultiplier] = useState(1);
  const [labelsStyle, setLabelsStyle] = useState({});
  const [cardStyle, setCardStyle] = useState({ height: 400, width: 550 });
  const [backgroundColor, setBackgroundColor] = useState("#4dc9f6");
  const [options, setOptions] = useState({});

  const validateAndSetConfig = async () => {
    if (!config) {
      setError(true);
      setErrorText("No configuration passed to BarSeries01");
      return false;
    }
    let cfg = await JSON.parse(config.chartCustomization);
    setChartConfiguration(cfg);
    return true;
  };

  const deriveChartVarsFromChartCustomization = async () => {
    if (chartConfiguration) {
      if (chartConfiguration?.title) {
        setTitle(chartConfiguration.title.title);
      }
      if (chartConfiguration?.cardStyle) {
        setCardStyle(chartConfiguration.cardStyle);
      }
      if (chartConfiguration?.chartSize?.chartWidth) {
        setChartWidth(chartConfiguration.chartSize.chartWidth);
      }
      if (chartConfiguration?.chartSize?.chartHeight) {
        setChartHeight(chartConfiguration.chartSize.chartHeight);
      }
      if (chartConfiguration?.discreteColorLegend) {
        setLegend(chartConfiguration.discreteColorLegend);
      }
      if (chartConfiguration?.labelsRadiusMultiplier) {
        setLabelsRadiusMultiplier(chartConfiguration.labelsRadiusMultiplier);
      }
      if (chartConfiguration?.labelsStyle) {
        setLabelsStyle(chartConfiguration.labelsStyle);
      }
      if (chartConfiguration?.options) {
        setOptions(chartConfiguration.options);
      }
    }
  };

const formatDollarAmount = (amount) => {
  const formattedAmount = amount.toFixed(0); // Format with two decimal places
  const parts = formattedAmount.toString().split("."); // Split into dollars and cents
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","); // Add commas for thousands separator
  return `$${parts.join(".")}`; // Combine dollars and cents and add dollar sign
};

  useEffect(() => {
    startUp();
    console.log("BasicSeries config", config);
  }, [config]);

  useEffect(() => {
    deriveChartVarsFromChartCustomization();
  }, [chartConfiguration]);

  const startUp = async () => {
    let validated = await validateAndSetConfig();
    if (validated) {
      let url = config.url;
      let dataAPIResult = await getChartDataGenericCall(
        url,
        chartId,
        usercode,
        filters
      );
      setData(dataAPIResult.data);
    }
  };

  // ... Rest of your component code

  if (data && !!data.length && chartConfiguration && !error) {
    console.log("basicSeriesThree Data", data);
     // Extracting x values with their respective years
    const formattedData = data.map((item) => ({
      year: item["year"],
      value: `(${item["year"]}) ${formatDollarAmount(item["x"])}`, // Assuming a function formatDollarAmount is defined
    }));

  // Extracting years to ensure all years are included
  const allYears = Array.from(new Set(data.map(item => item["year"])));

  // Creating an array to hold x values with their respective years
  const xValuesWithYears = allYears.map(year => {
    const valueObj = formattedData.find(item => item.year === year);
    return valueObj ? valueObj.value : "";
  });

  // Returning the NumberDisplay component with the modified data
  return (
    <NumberDisplay
      data={xValuesWithYears}
      title={title}
      cardStyle={cardStyle}
    />
  );
  } else if (data && !data.length) {
    // Handle no data case
    return (
      <div className="card d-inline-block card-chart m-3 p-2">
        <div className=" d-flex justify-content-center align-items-center">
          No data to display for {JSON.stringify(chartConfiguration.title)}
        </div>
      </div>
    );
  } else if (error) {
    // Handle error case
    return (
      <div className="card d-inline-block card-chart m-3 p-2">
        <div className=" d-flex justify-content-center align-items-center">
          Error: {errorText}
        </div>
      </div>
    );
  } else {
    // Handle loading case
    return (
      <div className="card d-inline-block card-chart m-3 p-2">
        <div className=" d-flex justify-content-center align-items-center">
          <LoadingIcon />
        </div>
      </div>
    );
  }
};

export default BasicSeriesThree;
