import React from 'react'
import { XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, MarkSeries, LineMarkSeries, DiscreteColorLegend, VerticalBarSeriesCanvas, } from 'react-vis';
import LoadingIcon from '../../../Components/LoadingIcon/LoadingIcon'

const NonOptimalShipmentsChart = ({ nonOptimalShipmentsData }) => {
    const kissnerBlue = "#4f81bd"
    const kissnerRed = '#C0504D'
    const kissnerGreen = '#32cd32'

    let createNumShipmentsData = (nonOptimalShipmentsData) => {
        let newArray = []
        for (let shipmentPeriod of nonOptimalShipmentsData) {
            let newObject = {}
            newObject.x = Object.keys(shipmentPeriod)[0]
            newObject.y = Object.values(shipmentPeriod)[0].total
            newArray.push(newObject)
        }
        return newArray
    }

    let createMilesData = (nonOptimalShipmentsData) => {
        let newArray = []
        for (let shipmentPeriod of nonOptimalShipmentsData) {
            // console.log('wut', shipmentPeriod)
            let newObject = {}
            newObject.x = Object.keys(shipmentPeriod)[0]
            newObject.y = Object.values(shipmentPeriod)[0].miles
            newArray.push(newObject)
        }
        return newArray
    }

    let rightMargin = 50
    let chartWidth = 430

    let tickFormat = (t, i) => {
        if ((i + 1) % 2 === 0) {
            return t.split(',')[0];
        } else {
            return;
        }
    }

    if (nonOptimalShipmentsData) {

        let numShipments = createNumShipmentsData(nonOptimalShipmentsData)
        let milesData = createMilesData(nonOptimalShipmentsData)

        return (
            <>

                <div className='card m-3 width450px'>
                    <h5 className="card-title text-center">Non Optimal Shipments</h5>

                    <div>
                        <XYPlot height={400} width={chartWidth} xType="ordinal" margin={{ bottom: 120, right: rightMargin }}>

                            <HorizontalGridLines />
                            <XAxis tickLabelAngle={-90} tickFormat={tickFormat} />
                            <YAxis title='shipments' position='end' />

                            {/* Bar series? */}
                            <VerticalBarSeries
                                cluster="2015"
                                color={kissnerBlue}
                                data={numShipments}
                            />
                            {/* <YAxis orientation='right' title="wut" />
                        <LineSeries
                            color={kissnerRed}
                            data={milesData} /> */}
                        </XYPlot>
                    </div>
                    <div style={{ position: 'absolute', top: 0 }} className="mt-3">

                        <XYPlot height={400} width={chartWidth} xType="ordinal" margin={{ bottom: 120, right: rightMargin }} >
                            <YAxis orientation='right' title='miles' position='end' />
                            <LineSeries
                                color={kissnerRed}
                                data={milesData} />
                        </XYPlot>
                    </div>
                </div>
            </>
        )
    } else {
        return (<div className='m-3 width450px d-flex justify-content-center align-items-center'>
            <LoadingIcon />
        </div>)
    }
}

export default NonOptimalShipmentsChart
