import React, { useState, useEffect } from "react"
import { Bar } from "react-chartjs-2";
import getChartDataGenericCall from "../../API/GenericAPICalls/getChartDataGenericCall";
import LoadingIcon from "../../../../Components/LoadingIcon/LoadingIcon";
import DownloadToExcelIcon from "../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon";


const BarSeries04 = ({ config, keynumber, chartId, subuserId, usercode, filters, handleShow, handleClose, setSelectedChartIdDownload }) => {
    const [data, setData] = useState(null)
    const [chartConfiguration, setChartConfiguration] = useState(null)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [title, setTitle] = useState("")
    const [cardStyle, setCardStyle] = useState({ height: 650, width: 1000 })
    const [backgroundColor, setBackgroundColor] = useState("#4dc9f6")
    const [options, setOptions] = useState({})




    useEffect(() => {
        startUp()
        console.log('BarSeries04 config', config)

    }, [config])

    useEffect(() => {
        deriveChartVarsFromChartCustomization()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartConfiguration])

    const startUp = async () => {
        let validated = await validateAndSetConfig()
        if (validated) {
            let url = config.url
            let dataAPIResult = await getChartDataGenericCall(url, chartId, usercode, filters)
            setData(dataAPIResult.data)

        }
    }

    const COLORS = [
        "#4dc9f6",
        "#f67019",
        "#f53794",
        "#537bc4",
        "#acc236",
        "#166a8f",
        "#00a950",
        "#58595b",
        "#8549ba",
        "#f4c63d",
        "#d17905",
        "#ff8f32",
        "#a4a61d",
        "#eacf7d",
        "#86797d",
        "#b2d235",
        "#de9ed6",
        "#88b04b",
        "#f697c1",
        "#f158bf",
        "#e145ba",
        "#ee91e3",
        "#d291bc",
        "#f1e788",
        "#92e1c0",
        "#9fe1e7",
        "#9fc6e7"
    ];


    const validateAndSetConfig = async () => {
        if (!config) {
            setError(true)
            setErrorText("No configuration passed to BarSeries04")
            return false
        }
        let cfg = await JSON.parse(config.chartCustomization)
        setChartConfiguration(cfg)
        return true
    }


    const deriveChartVarsFromChartCustomization = async () => {
        if (chartConfiguration) {
            // console.log("chartConfiguration", chartConfiguration)
            if (chartConfiguration?.title) {
                setTitle(chartConfiguration.title.title)
            }
            if (chartConfiguration?.cardStyle) {
                setCardStyle(chartConfiguration.cardStyle)
            }
            if (chartConfiguration?.backgroundColor) {
                setBackgroundColor(chartConfiguration.backgroundColor)
            }
            if (chartConfiguration?.options) {
                setOptions(chartConfiguration.options)
            }
        }
    }

    if (data && chartConfiguration && !error) {
         console.log("BarSeries04 data", data)
        // console.log({ datasets: [{ data: data.map(item => item.y) }] })
        let datasets = [{ label: chartConfiguration.label, data: data.map(item => item.y), backgroundColor: backgroundColor }]
        let labels = data.map(item => item.x)
        // if the first item in data contains a property called group then loop through and group multiple datasets by the group dimension
        // Also need the x dimension to be the label

        // REMOVE 'GROUP' PROPERTY FROM CODE SO THAT THE X-AXIS CAN BE DISPLAYED AS THE X PROPERTIES INSTEAD OF MONTHS! 
        if (data[0].group) {
             labels = ['2019', '2020', '2021', '2022']

          
             let groupedData = data.reduce((r, a) => {
                 r[a.group] = [...r[a.group] || [], a];
                return r;
             }, {});
             console.log("groupedData", groupedData)
             console.log("Object.keys(groupedData)", Object.keys(groupedData))
            datasets = []

            Object.keys(groupedData).map((key, index) => {
                let color = COLORS[index]
                datasets.push({ label: key, data: groupedData[key].map(item => item.y), backgroundColor: color })
            })
            
        }
        console.log(datasets)
        return (
            <div className="card d-inline-block card-chart m-1 p-3 " style={cardStyle} key={keynumber}>
                <h5 className="card-title text-center">{title}</h5>
                {
                    config?.download && config?.download === "true" ?
                        <div className="d-flex flex-row-reverse"><DownloadToExcelIcon config={config} handleShow={handleShow} handleClose={handleClose} setSelectedChartIdDownload={setSelectedChartIdDownload} /></div>
                        : null
                }
                <div>
                    <Bar
                        height={200}
                        options={options}
                        data={
                            {
                                labels: labels,
                                datasets: datasets
                            }
                        }
                    />
                </div>
            </div>
        )
    } 
        else if (error) {
        return (
            <div className="card d-inline-block card-chart m-3 p-2">
                <div className=" d-flex justify-content-center align-items-center">
                    Error: {errorText}
                </div>
            </div>
        )
    } else {
        return (
            <div className="card d-inline-block card-chart m-3 p-2">
                <div className=" d-flex justify-content-center align-items-center">
                    <LoadingIcon />
                </div>
            </div>
        )
    }
}

export default BarSeries04
