import React from 'react'
import LoadingIcon from '../../../Components/LoadingIcon/LoadingIcon'

const LaneCostsTable = ({ laneCosts }) => {

    if (laneCosts) {
        return (
            <div className="width500px">
                <table className="table table-sm table-bordered x-small-font" >
                    <thead>
                        <tr className="text-center">
                            <th></th>
                            <th>Delivered Orders</th>
                            <th>Total Cost</th>
                            <th>Avg.Cost / Shipment</th>
                            <th>FY20 Comparable Shipments</th>
                            <th>% ∆ FY20 to FY21</th>
                            <th>FY21 Matrix Comparable Shipments</th>
                            <th>% ∆ to FY21 Freight Matrix</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(laneCosts).map(([city, value]) => {
                            return (
                                <tr>
                                    <td>{city.toUpperCase()}</td>
                                    <td>{value['deliveries']}</td>
                                    <td>{Math.ceil(value['cost'] * 100) / 100}</td>
                                    <td>{Math.ceil(value['avgCost'] * 100) / 100}</td>
                                    <td>{value['comp20Total']}</td>
                                    <td>{Math.ceil(value['diffFrom20'] * 100) / 100}</td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
        )
    } else {
        return (
            <div className='width500px d-flex justify-content-center align-items-center'>
                <LoadingIcon />
            </div>
        )
    }
}

export default LaneCostsTable
