import React, { useState, useEffect } from "react"
import { Bar } from "react-chartjs-2";
import getChartDataGenericCall from "../../API/GenericAPICalls/getChartDataGenericCall";
import LoadingIcon from "../../../../Components/LoadingIcon/LoadingIcon";
import DownloadToExcelIcon from "../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon";


const BigNumber01 = ({ config, keynumber, chartId, subuserId, usercode, filters, handleShow, handleClose, setSelectedChartIdDownload }) => {
    const [data, setData] = useState(null)
    const [chartConfiguration, setChartConfiguration] = useState(null)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [title, setTitle] = useState("")
    const [cardStyle, setCardStyle] = useState({ height: 350, width: 350 })
    const [backgroundColor, setBackgroundColor] = useState("#4dc9f6")
    const [options, setOptions] = useState({})

    useEffect(() => {
        startUp()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config])

    useEffect(() => {
        deriveChartVarsFromChartCustomization()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartConfiguration])

    const startUp = async () => {
        let validated = await validateAndSetConfig()
        if (validated) {
            let url = config.url
            let dataAPIResult = await getChartDataGenericCall(url, chartId, usercode, filters)
            setData(dataAPIResult.data)

        }
    }

    const validateAndSetConfig = async () => {
        if (!config) {
            setError(true)
            setErrorText("No configuration passed to BigNumber01")
            return false
        }
        let cfg = await JSON.parse(config.chartCustomization)
        setChartConfiguration(cfg)
        return true
    }


    const deriveChartVarsFromChartCustomization = async () => {
        if (chartConfiguration) {
            if (chartConfiguration?.title) {
                setTitle(chartConfiguration.title.title)
            }
            if (chartConfiguration?.cardStyle) {
                setCardStyle(chartConfiguration.cardStyle)
            }
            if (chartConfiguration?.backgroundColor) {
                setBackgroundColor(chartConfiguration.backgroundColor)
            }
        }
    }

    if (data && chartConfiguration && !error) {

        console.log("BigNumber01 data", data)
        // console.log({ datasets: [{ data: data.map(item => item.y) }] })

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',

        });

        return (
            <div className="card d-inline-block card-chart m-1 p-3 " style={cardStyle} key={keynumber}>
                <h5 className="card-title text-center">{title}</h5>
                {
                    config?.download && config?.download === "true" ?
                        <div className="d-flex flex-row-reverse"><DownloadToExcelIcon config={config} handleShow={handleShow} handleClose={handleClose} setSelectedChartIdDownload={setSelectedChartIdDownload} /></div>
                        : null
                }
                <div className="d-flex align-items-center justify-content-center grow">
                    <div className="flex-fill mt-4 text-success" style={{ textAlign: "center" }} >
                        <h1>{formatter.format(data[0].val)}</h1>
                    </div>
                </div>
            </div>
        )
    } else if (error) {
        return (
            <div className="card d-inline-block card-chart m-3 p-2">
                <div className=" d-flex justify-content-center align-items-center">
                    Error: {errorText}
                </div>
            </div>
        )
    } else {
        return (
            <div className="card d-inline-block card-chart m-3 p-2">
                <div className=" d-flex justify-content-center align-items-center">
                    <LoadingIcon />
                </div>
            </div>
        )
    }
}

export default BigNumber01
