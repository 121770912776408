import { XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, MarkSeries, LineMarkSeries, DiscreteColorLegend, VerticalBarSeriesCanvas, } from 'react-vis';
import LoadingIcon from '../../../Components/LoadingIcon/LoadingIcon'

import React from 'react'

const AverageFreightRateByWeekChart = ({ rateByWeek }) => {

    const kissnerBlue = "#4f81bd"
    const kissnerRed = '#C0504D'
    const kissnerGreen = '#32cd32'

    let createNumShipmentsData = (rateByWeek) => {
        let newArray = []
        for (let rate of rateByWeek) {
            let newObject = {}
            newObject.x = Object.keys(rate)[0]
            newObject.y = Object.values(rate)[0].total
            newArray.push(newObject)
        }
        return newArray
    }


    let createAverageMilesData = (rateByWeek) => {
        let newArray = []
        for (let rate of rateByWeek) {
            let newObject = {}
            newObject.x = Object.keys(rate)[0]
            newObject.y = Object.values(rate)[0].miles
            newArray.push(newObject)
        }
        return newArray
    }

    let createAverageRateData = (rateByWeek) => {
        let newArray = []
        for (let rate of rateByWeek) {
            let newObject = {}
            newObject.x = Object.keys(rate)[0]
            newObject.y = Object.values(rate)[0].rate
            newArray.push(newObject)
        }
        return newArray
    }

    let rightMargin = 50
    let chartWidth = 430

    let tickFormat = (t, i) => {
        if ((i + 1) % 2 === 0) {
            return t.split(',')[0];
        } else {
            return;
        }
    }

    if (rateByWeek) {
        let numShipmentsData = createNumShipmentsData(rateByWeek)
        let milesData = createAverageMilesData(rateByWeek)
        let rateData = createAverageRateData(rateByWeek)
        return (
            <div className='card m-3 width500px'>
                <h5 className="card-title text-center">Average Freight Rate by Week</h5>
                <div>
                    <XYPlot height={400} width={chartWidth} xType="ordinal" margin={{ bottom: 120, right: rightMargin }}>
                        <DiscreteColorLegend
                            style={{ position: 'absolute', left: '380px', top: '280px', width: '200px' }}
                            orientation="vertical"
                            items={[
                                {
                                    title: '# Shipments',
                                    color: kissnerBlue
                                },
                                {
                                    title: 'Avg Miles',
                                    color: kissnerGreen
                                },
                                {
                                    title: 'Avg Rate',
                                    color: kissnerRed
                                }
                            ]}
                        />


                        <HorizontalGridLines />
                        <XAxis tickLabelAngle={-90} tickFormat={tickFormat} />

                        <VerticalBarSeries
                            cluster="2015"
                            color={kissnerBlue}
                            data={numShipmentsData}
                        />

                    </XYPlot>
                </div>
                <div style={{ position: 'absolute', top: 0 }} className="mt-3">
                    <XYPlot height={400} width={chartWidth} xType="ordinal" margin={{ bottom: 120, right: rightMargin }} >
                        <YAxis orientation='right' />
                        <LineSeries
                            color={kissnerRed}
                            data={rateData}
                        />
                    </XYPlot>
                </div>
                <div style={{ position: 'absolute', top: 0 }} className="mt-3">
                    <XYPlot height={400} width={chartWidth} xType="ordinal" margin={{ bottom: 120, right: rightMargin }} >
                        <YAxis orientation='left' />

                        <LineSeries
                            color={kissnerGreen}
                            data={milesData}
                        />
                    </XYPlot>
                </div>
            </div>
        )
    } else {
        return (<div className='m-3 width500px d-flex justify-content-center align-items-center'>
            <LoadingIcon />
        </div>)
    }
}

export default AverageFreightRateByWeekChart
