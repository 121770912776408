import './App.css';
import { Router } from "react-router-dom";
import History from "./Services/History/History";
import Routes from "./Services/Routes/Routes";

function App() {

  return (
    <div className="App">
      <Router history={History}>
        <Routes />
      </Router>
    </div>
  );
}

export default App;
