import React from 'react'
import Kissner from '../../Customers/Kissner/Kissner'

const KissnerDashboard = () => {
    return (
        <div className='m-3'>
            <div className="row">
                <div className="col">
                    {/* <h1>Page / Dashboad </h1> */}
                </div>
            </div>
            <div className="row">

                <Kissner />

            </div>
        </div>
    )
}

export default KissnerDashboard
