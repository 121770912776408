import React from 'react'
import LoadingIcon from '../../../Components/LoadingIcon/LoadingIcon'

const OrdersTodayTable = ({ ordersTopTableData }) => {
    let today = new Date()
    let yesterday = today.setDate(today.getDate() - 1);

    if (ordersTopTableData) {
        return (
            <table className="table table-sm table-bordered x-small-font">
                <thead>
                    <tr className="text-center">
                        <th></th>
                        <th>Shipped Orders</th>
                        <th>Late Orders</th>
                        <th>Orders to Ship - Rolling Week</th>
                        <th>Orders to Ship - Current Month</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colSpan="1">
                            <table className="table table-bordered">
                                <thead>
                                    <tr><td>&nbsp;</td></tr>
                                    {/* city names */}
                                    {ordersTopTableData ?
                                        Object.keys(ordersTopTableData).map((city, index) => {
                                            if (city !== "totals") {
                                                return <tr><td>{city}</td></tr>
                                            } else {
                                                return <tr><td>{new Date(yesterday).toLocaleDateString()}</td></tr>
                                            }
                                        })

                                        : null}
                                </thead>
                            </table>
                        </td>
                        <td colSpan="1">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Bulk</th>
                                        <th>Dry Van</th>
                                        <th>Flatbed</th>
                                        <th>Intermodal Rail</th>
                                        <th>Pickup</th>
                                        <th>Total Shipped</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(ordersTopTableData).map(([city, value]) => {
                                        return (
                                            <tr>
                                                <td>{value.yesterday.bulk}</td>
                                                <td>{value.yesterday.dryVan}</td>
                                                <td>{value.yesterday.flatbed}</td>
                                                <td>{value.yesterday.rail}</td>
                                                <td>{value.yesterday.pickups}</td>
                                                <td>{value.yesterday.total}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </td>
                        <td colSpan="1">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Delivery</th>
                                        <th>Pickup</th>
                                        <th>Total Late</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(ordersTopTableData).map(([city, value]) => {
                                        return (
                                            <tr>
                                                <td>{value.late.deliveries}</td>
                                                <td>{value.late.pickups}</td>
                                                <td>{value.late.total}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </td>
                        <td colSpan="1">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Delivery</th>
                                        <th>Pickup</th>
                                        <th>Total to Ship</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(ordersTopTableData).map(([city, value]) => {
                                        return (
                                            <tr>
                                                <td>{value.rollingWeek.deliveries}</td>
                                                <td>{value.rollingWeek.pickups}</td>
                                                <td>{value.rollingWeek.total}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </td>
                        <td colSpan="1">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>Delivery</th>
                                        <th>Pickup</th>
                                        <th>Total to Ship</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Object.entries(ordersTopTableData).map(([city, value]) => {

                                        return (
                                            <tr>
                                                <td>{value.thisMonth.deliveries}</td>
                                                <td>{value.thisMonth.pickups}</td>
                                                <td>{value.thisMonth.total}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table >
        )
    } else {
        return (<div className='m-3 d-flex justify-content-center align-items-center'>
            <LoadingIcon />
        </div>)
    }
}

export default OrdersTodayTable
