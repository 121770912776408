//Chart.JS https://www.chartjs.org/docs/latest/samples/
//https://github.com/reactchartjs/react-chartjs-2/

import React, { useState, useEffect } from 'react'
import { Doughnut } from 'react-chartjs-2';
import getChartDataGenericCall from '../../API/GenericAPICalls/getChartDataGenericCall';
import LoadingIcon from '../../../../Components/LoadingIcon/LoadingIcon';
import DownloadToExcelIcon from '../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon';


const Doughnut01 = ({ config, keynumber, chartId, subuserId, usercode, filters, handleShow, handleClose, setSelectedChartIdDownload }) => {
    const [data, setData] = useState(null)
    const [chartConfiguration, setChartConfiguration] = useState(null)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [title, setTitle] = useState("")
    const [cardStyle, setCardStyle] = useState({ width: 550, height: 400 })
    const [chartWidth, setChartWidth] = useState(300)
    const [chartHeight, setChartHeight] = useState(300)
    const [radialChartDivStyle, setRadialChartDivStyle] = useState({ display: 'flex', justifyContent: 'center' })
    const [legend, setLegend] = useState(null)
    const [labelsRadiusMultiplier, setLabelsRadiusMultiplier] = useState(1)
    const [labelsStyle, setLabelsStyle] = useState({})
    const [options, setOptions] = useState({})


    useEffect(() => {
        startUp()
    }, [config])

    useEffect(() => {
        deriveChartVarsFromChartCustomization()
    }, [chartConfiguration])

    const startUp = async () => {
        let validated = await validateAndSetConfig()
        if (validated) {
            let url = config.url
            let dataAPIResult = await getChartDataGenericCall(url, chartId, usercode, filters)
            setData(dataAPIResult.data)

        }
    }

    const COLORS = [
        '#4dc9f6',
        '#f67019',
        '#f53794',
        '#537bc4',
        '#acc236',
        '#166a8f',
        '#00a950',
        '#58595b',
        '#8549ba'
    ];


    const validateAndSetConfig = async () => {
        if (!config) {
            setError(true)
            setErrorText('No configuration passed to BarSeries01')
            return false
        }
        let cfg = await JSON.parse(config.chartCustomization)
        setChartConfiguration(cfg)
        return true
    }

    const deriveChartVarsFromChartCustomization = async () => {
        if (chartConfiguration) {
            if (chartConfiguration?.title) {
                setTitle(chartConfiguration.title.title)
            }
            if (chartConfiguration?.cardStyle) {
                setCardStyle(chartConfiguration.cardStyle)
            }
            if (chartConfiguration?.chartSize?.chartWidth) {
                setChartWidth(chartConfiguration.chartSize.chartWidth)
            }
            if (chartConfiguration?.chartSize?.chartHeight) {
                setChartHeight(chartConfiguration.chartSize.chartHeight)
            }
            if (chartConfiguration?.discreteColorLegend) {
                setLegend(chartConfiguration.discreteColorLegend)
            }
            if (chartConfiguration?.labelsRadiusMultiplier) {
                setLabelsRadiusMultiplier(chartConfiguration.labelsRadiusMultiplier)
            }
            if (chartConfiguration?.labelsStyle) {
                setLabelsStyle(chartConfiguration.labelsStyle)
            }
            if (chartConfiguration?.options) {
                setOptions(chartConfiguration.options)
            }
        }
    }
    
    // const myData = [{ label: 'Super Custom label', angle: 1 }, { angle: 2, label: 'Super Custom label', subLabel: 'With annotation' }, { angle: 5, label: 'Alt Label' }, { angle: 3 }, { angle: 5, subLabel: 'Sub Label only' }]

    if (data && chartConfiguration && !error) {
        // console.log({ datasets: [{ data: data.map(item => item.y) }] })
        console.log('this is chart configurations options:', chartConfiguration.options)
        return (
            <div className='card card-chart d-inline-block m-1 p-3 ' style={cardStyle} key={keynumber}>
                <h5 className="card-title text-center">{title}</h5>
                {
                    config?.download && config?.download === "true" ?
                        <div className="d-flex flex-row-reverse"><DownloadToExcelIcon config={config} handleShow={handleShow} handleClose={handleClose} setSelectedChartIdDownload={setSelectedChartIdDownload} /></div>
                        : null
                }
                <div style={radialChartDivStyle}>
                    <Doughnut height={325} options={options} data={{ labels: data.map(item => item.label), datasets: [{ data: data.map(item => item.angle), backgroundColor: COLORS }] }} />
                </div>
            </div>
        )
    } else if (error) {
        return (
            <div className='card d-inline-block m-3 p-2'>
                <div className=' d-flex justify-content-center align-items-center'>
                    Error: {errorText}
                </div>
            </div>
        )
    } else {
        return (
            <div className='card d-inline-block m-3 p-2'>
                <div className=' d-flex justify-content-center align-items-center'>
                    <LoadingIcon />
                </div>
            </div>
        )
    }
}

export default Doughnut01
