import React, { useState, useEffect } from 'react';
import getChartDataGenericCall from '../../API/GenericAPICalls/getChartDataGenericCall';
import LoadingIcon from '../../../../Components/LoadingIcon/LoadingIcon';
import TruckIcon from '../../../../Components/LoadingIcon/TruckIcon/TruckIcon.js'

import DownloadToExcelIcon from '../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon';

/* This component is designed to pass in and display a single data point, e.g. Shipment Counts YTD. It was originally created to includ e a TruckIcon to display next
to this count. The Query must alias the data as 'x' in order to display.  */

const NumberDisplay = ({ data, title, cardStyle }) => {
    
    return (
        <div className="card card-chart d-inline-block m-1 p-3" style={cardStyle}>
            <h5 className="card-title text-center">{title}</h5>
            <div className="text-center">
            <div className="display-number" style={{ fontSize: '72px'}}>
                <span style={{marginRight: '30px'}}> {/* Add margin to create space */}
                    <TruckIcon />
                </span>
                {data}
                
                </div>
            </div>
        </div>
    );
};

const BasicSeries = ({ config, keynumber, chartId, subuserId, usercode, filters, handleShow, handleClose, setSelectedChartIdDownload }) => {
    const [data, setData] = useState(null);
    const [chartConfiguration, setChartConfiguration] = useState(null);
    const [error, setError] = useState(false);
    const [errorText, setErrorText] = useState("");
    const [title, setTitle] = useState("");
    const [chartWidth, setChartWidth] = useState(550)
    const [chartHeight, setChartHeight] = useState(200)
    const [legend, setLegend] = useState(null)
    const [labelsRadiusMultiplier, setLabelsRadiusMultiplier] = useState(1)
    const [labelsStyle, setLabelsStyle] = useState({})
    const [cardStyle, setCardStyle] = useState({ height: 400, width: 550 });
    const [backgroundColor, setBackgroundColor] = useState("#4dc9f6");
    const [options, setOptions] = useState({});


    const validateAndSetConfig = async () => {
        if (!config) {
            setError(true)
            setErrorText('No configuration passed to BarSeries01')
            return false
        }
        let cfg = await JSON.parse(config.chartCustomization)
        setChartConfiguration(cfg)
        return true
    }

    const deriveChartVarsFromChartCustomization = async () => {
        if (chartConfiguration) {
            if (chartConfiguration?.title) {
                setTitle(chartConfiguration.title.title)
            }
            if (chartConfiguration?.cardStyle) {
                setCardStyle(chartConfiguration.cardStyle)
            }
            if (chartConfiguration?.chartSize?.chartWidth) {
                setChartWidth(chartConfiguration.chartSize.chartWidth)
            }
            if (chartConfiguration?.chartSize?.chartHeight) {
                setChartHeight(chartConfiguration.chartSize.chartHeight)
            }
            if (chartConfiguration?.discreteColorLegend) {
                setLegend(chartConfiguration.discreteColorLegend)
            }
            if (chartConfiguration?.labelsRadiusMultiplier) {
                setLabelsRadiusMultiplier(chartConfiguration.labelsRadiusMultiplier)
            }
            if (chartConfiguration?.labelsStyle) {
                setLabelsStyle(chartConfiguration.labelsStyle)
            }
            if (chartConfiguration?.options) {
                setOptions(chartConfiguration.options)
            }
        }
    }

    useEffect(() => {
        startUp();
        console.log('BasicSeries config', config);
    }, [config]);

    useEffect(() => {
        deriveChartVarsFromChartCustomization();
    }, [chartConfiguration]);

    const startUp = async () => {
        let validated = await validateAndSetConfig();
        if (validated) {
            let url = config.url;
            let dataAPIResult = await getChartDataGenericCall(url, chartId, usercode, filters);
            setData(dataAPIResult.data);
        }
    }

    // ... Rest of your component code

    if (data && !!data.length && chartConfiguration && !error) {
        console.log('line 94: data', data)
        const countValue = data[0]['x'];
        return (
            <NumberDisplay data={countValue} title={title} cardStyle={cardStyle} />
        );
    } else if (data && !data.length) {
        // Handle no data case
        return (
            <div className="card d-inline-block card-chart m-3 p-2">
                <div className=" d-flex justify-content-center align-items-center">
                    No data to display for {JSON.stringify(chartConfiguration.title)}
                </div>
            </div>
        )
    } else if (error) {
        // Handle error case
        return (
            <div className="card d-inline-block card-chart m-3 p-2">
                <div className=" d-flex justify-content-center align-items-center">
                    Error: {errorText}
                </div>
            </div>
        )
    } else {
        // Handle loading case
        return (
            <div className="card d-inline-block card-chart m-3 p-2">
                <div className=" d-flex justify-content-center align-items-center">
                    <LoadingIcon />
                </div>
            </div>
        )
    }
}

export default BasicSeries;
