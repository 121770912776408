import React, { useState, useEffect } from 'react'
import { Line } from 'react-chartjs-2';
import getChartDataGenericCall from "../../API/GenericAPICalls/getChartDataGenericCall";
import LoadingIcon from '../../../../Components/LoadingIcon/LoadingIcon';
import DownloadToExcelIcon from "../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon";
import { Chart as ChartJS,
  CategoryScale, // x-axis
  LinearScale, // y-axis
  PointElement, // adds the dots in the lines
  LineElement, // adds the lines 
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

const LineSeries01 = ({ config, keynumber, chartId, subuserId, usercode, filters, handleShow, handleClose, setSelectedChartIdDownload }) => {
  const [data, setData] = useState(null)
  const [chartConfiguration, setChartConfiguration] = useState(null)
  const [error, setError] = useState(false)
  const [errorText, setErrorText] = useState("")
  const [Title, setTitle] = useState("")
  const [cardStyle, setCardStyle] = useState({ height: 400, width: 550 })
  const [backgroundColor, setBackgroundColor] = useState("#4dc9f6")
  const [options, setOptions] = useState({})

  useEffect(() => {
    startUp()
    console.log('LineSereies01 config', config)

  }, [config])

  useEffect(() => {
    deriveChartVarsFromChartCustomization()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartConfiguration])


  // ChartJS.register is a method provided by ChartJS that allows you to register custom components for use with charts.
  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const startUp = async () => {
    let validated = await validateAndSetConfig()
    if (validated) {
      let url = config.url
      let dataAPIResult = await getChartDataGenericCall(url, chartId, usercode, filters)
      setData(dataAPIResult.data)

    }
  }

  const COLORS = [
    "#4dc9f6",
    "#f67019",
    "#f53794",
    "#537bc4",
    "#acc236",
    "#166a8f",
    "#00a950",
    "#58595b",
    "#8549ba"
  ];

  const validateAndSetConfig = async () => {
    if (!config) {
      setError(true)
      setErrorText("No configuration passed to LineSeries01")
      return false
    }
    let cfg = await JSON.parse(config.chartCustomization)
    setChartConfiguration(cfg)
    return true
  }

  const deriveChartVarsFromChartCustomization = async () => {
    if (chartConfiguration) {
      // console.log("chartConfiguration", chartConfiguration)
      if (chartConfiguration?.Title) {
        setTitle(chartConfiguration.Title.Title)
      }
      if (chartConfiguration?.cardStyle) {
        setCardStyle(chartConfiguration.cardStyle)
      }
      if (chartConfiguration?.backgroundColor) {
        setBackgroundColor(chartConfiguration.backgroundColor)
      }
      if (chartConfiguration?.options) {
        setOptions(chartConfiguration.options)
      }
    }
  }

  if (data && chartConfiguration && !error) {
    // console.log("BarSeries03 data", data)
    // console.log({ datasets: [{ data: data.map(item => item.y) }] })
    // let datasets = [{ label: chartConfiguration.label, data: data.map(item => item.y), backgroundColor: backgroundColor }]
    // let datasets = [{label: chartConfiguration.label, data: data.map(item => ({ y1: item.y1, y2: item.y2, y3: item.y3 })),backgroundColor: backgroundColor}]
    let datasets = [
      {
        label: 'Overall',
        data: data.map(item => ({x: item.x, y: item.y})),
        borderColor: 'red',
        fill: false
      }
    ]
    let labels = data.map(item => item.x) 
    console.log('this is datasets', datasets)
    console.log('this is all data!:', data)
    // if the first item in data contains a property called group then loop through and group multiple datasets by the group dimension
    // Also need the x dimension to be the label
    if (data[0].group) {
        labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let groupedData = data.reduce((r, a) => {
            r[a.group] = [...r[a.group] || [], a];
            return r;
        }, {}); 
        // console.log("groupedData", groupedData)
        // console.log("Object.keys(groupedData)", Object.keys(groupedData))
        datasets = [] 

        Object.keys(groupedData).map((key, index) => {
            let color = COLORS[index]
            datasets.push({ label: key, data: groupedData[key].map(item => item.y), backgroundColor: color })
        }) 
      }
    return (
      <div className="card d-inline-block card-chart m-1 p-3 " style={cardStyle} key={keynumber}>
        <h5 className="card-Title text-center">{Title}</h5>
        {
          config?.download && config?.download === "true" ?
            <div className="d-flex flex-row-reverse"><DownloadToExcelIcon config={config} handleShow={handleShow} handleClose={handleClose} setSelectedChartIdDownload={setSelectedChartIdDownload} /></div>
            : null
        }
        <div>
          <Line
            height={200}
            options={options}
            data={
                {labels: labels,
                datasets: datasets     
                }      
            }
          />
        </div>

      </div>
    )
  } else if (error) {
    return (
      <div className="card d-inline-block card-chart m-3 p-2">
        <div className=" d-flex justify-content-center align-items-center">
          Error: {errorText}
        </div>
      </div>
    )
  } else {
    return (
      <div className="card d-inline-block card-chart m-3 p-2">
        <div className=" d-flex justify-content-center align-items-center">
          <LoadingIcon />
        </div>
      </div>
    )
  }
}

export default LineSeries01