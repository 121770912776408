import { useState, useEffect } from 'react';
import getOrdersTopTableData from '../API/getOrdersTopTableData'
import getOrdersShippedPerDayData from '../API/getOrdersShippedPerDayData'
import getOrderTotalsByYear from '../API/getOrderTotalsByYear'
import getLaneCosts from '../API/getLaneCosts'
import getRateByWeek from '../API/getRateByWeek'
import getActaulFreightToMatrixRateData from '../API/getActualFreightToMatrixRateData'
import getNonOptimalShipmentsData from '../API/getNonOptimalShipmentsData'
import getNonOptimalShipmentsListData from '../API/getNonOptimalShipmentsListData'


const useData = () => {
    let [ordersTopTableData, setOrdersTopTableData] = useState(null)
    let [ordersShippedPerDayData, setOrdersShippedPerDayData] = useState(null)
    let [totalsByYearTableData, setTotalsByYearTableData] = useState(null)
    let [laneCosts, setLaneCosts] = useState(null)
    let [rateByWeek, setRateByWeek] = useState(null)
    let [actualFreightToMatrixData, setActualFreightToMatrixData] = useState(null)
    let [nonOptimalShipmentsData, setNonOptimalShipmentsData] = useState(null)
    let [nonOptimalShipmentsListData, setNonOptimalShipmentsListData] = useState(null)
    // Action starts here
    useEffect(() => {

        startUp()

    }, []) // eslint-disable-line



    // This only runs on startup 
    async function startUp() {
        let ordersData = await getOrdersTopTableData()
        setOrdersTopTableData(ordersData)
        let ordersByDay = await getOrdersShippedPerDayData()
        setOrdersShippedPerDayData(ordersByDay)
        let tottalsByYear = await getOrderTotalsByYear()
        setTotalsByYearTableData(tottalsByYear)
        let laneCostsData = await getLaneCosts()
        setLaneCosts(laneCostsData)
        let rateByWeekData = await getRateByWeek()
        setRateByWeek(rateByWeekData)
        let actualFreightData = await getActaulFreightToMatrixRateData()
        setActualFreightToMatrixData(actualFreightData)
        let nonOptmalData = await getNonOptimalShipmentsData()
        setNonOptimalShipmentsData(nonOptmalData)
        let nonOptmalListData = await getNonOptimalShipmentsListData()
        setNonOptimalShipmentsListData(nonOptmalListData)
    }



    return { ordersTopTableData, ordersShippedPerDayData, totalsByYearTableData, laneCosts, rateByWeek, actualFreightToMatrixData, nonOptimalShipmentsData, nonOptimalShipmentsListData }
}

export default useData