// import moment from 'moment';



export const submitEmailDataRequest = async (chartid, emails, usercode, setModalSubmitText, setModalSubmitAlertType) => {
    console.log('submitEmailDataRequest', chartid, emails, usercode)


    setModalSubmitText("Submited....")
    setModalSubmitAlertType("alert alert-secondary")

    let firstTry = await APICall(30000)
    // console.log('submitEmailDataRequest first', firstTry)
    if (typeof firstTry === "object") {
        return firstTry
    } else {
        let secondTry = await APICall(40000)
        // console.log('submitEmailDataRequest second', secondTry)
        if (typeof secondTry === "object") {
            return secondTry
        } else {
            let thridTry = await APICall(90000)
            // console.log('submitEmailDataRequest third', thridTry)
            if (typeof thirdTry === "object") {
                return thridTry
            } else {
                console.warn("submitEmailDataRequest saw an api call fail 3x")
                return 'failed'
            }
        }
    }

    async function APICall(timeOutValue) {

        return new Promise(function (resolve, reject) {
            let controller = new AbortController();
            if (timeOutValue) {
                setTimeout(() => controller.abort(), timeOutValue);
            }
            var myHeaders = new Headers();
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            let url = "https://api.shippersedge.com/analyze/emailChartData/?chartid=" + chartid + "&email=" + emails + "&usercode=" + usercode

            fetch(url, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    console.log(result.status)
                    if (result.status === "success") {

                        setModalSubmitText("Request successfully submited. Please allow some time to generate the report and recieve the email.")
                        setModalSubmitAlertType("alert alert-success")
                    }
                    if (result.status === "failed") {
                        setModalSubmitText("We encountered an error: " + result.message)
                        setModalSubmitAlertType("alert alert-warning")
                    }
                })
                .catch(error => {
                    console.log('error', error)
                    setModalSubmitText("We encountered an error fetching data.")
                    setModalSubmitAlertType("alert alert-warning")
                });

        });
    }
};

export default submitEmailDataRequest