// import moment from 'moment';



export const getRateByWeek = async () => {


    let firstTry = await APICall(10000)
    // console.log('getRateByWeek first', firstTry)
    if (typeof firstTry === "object") {
        return firstTry
    } else {
        let secondTry = await APICall(10000)
        // console.log('getRateByWeek second', secondTry)
        if (typeof secondTry === "object") {
            return secondTry
        } else {
            let thridTry = await APICall(10000)
            // console.log('getRateByWeek third', thridTry)
            if (typeof thirdTry === "object") {
                return thridTry
            } else {
                console.warn("getRateByWeek saw an api call fail 3x")
                return 'failed'
            }
        }
    }

    async function APICall(timeOutValue) {

        return new Promise(function (resolve, reject) {

            let controller = new AbortController();

            let url = "https://api.shippersedge.com/analyze/client/kissner/rate-by-week"

            let myHeaders = new Headers();
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            let request = new Request(url, requestOptions);

            if (timeOutValue) {
                setTimeout(() => controller.abort(), timeOutValue);
            }


            fetch(request)
                .then((response) => {
                    // console.log(
                    //   "debug getRateByWeek.js response",
                    //   response
                    // );
                    if (!response.ok) {
                        console.error(
                            "getRateByWeek.js error",
                            response.statusText,
                            response
                        );
                        throw Error(response.statusText);
                    }
                    return response.json();
                })
                .then((response) => {
                    // console.log('getRateByWeek', response)
                    resolve(response);
                })
                .catch((err) => {
                    console.error("getRateByWeek API Request failed", err);
                    resolve(err);
                });
        });
    }
};

export default getRateByWeek