import React, { useState, useEffect } from 'react'
import LoadingIcon from '../../../../Components/LoadingIcon/LoadingIcon';
import {
    XYPlot,
    XAxis,
    YAxis,
    VerticalGridLines,
    HorizontalGridLines,
    MarkSeries,
    DiscreteColorLegend
} from 'react-vis';
import getChartDataGenericCall from '../../API/GenericAPICalls/getChartDataGenericCall';
import DownloadToExcelIcon from '../../../../Components/DownloadToExcelIcon/DownloadToExcelIcon';

const MarkSeries01 = ({ config, keynumber, chartId, subuserId, usercode, filters, handleShow, handleClose, setSelectedChartIdDownload }) => {


    const [data, setData] = useState(null)
    const [chartConfiguration, setChartConfiguration] = useState(null)
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const [title, setTitle] = useState("")
    const [cardStyle, setCardStyle] = useState({ height: 300, width: 300 })
    const [legend, setLegend] = useState(null)
    const [chartWidth, setChartWidth] = useState(300)
    const [chartHeight, setChartHeight] = useState(300)
    const [chartMargin, setChartMargin] = useState({})
    const [xAxisTickLabelAngle, setXAxisTickLabelAngle] = useState(0)
    const [yAxisTickLabelAngle, setYAxisTickLabelAngle] = useState(0)
    const [xAxisTickValue, setXAxisTickValue] = useState(1)
    const [yAxisTitle, setYAxisTitle] = useState("")
    const [xAxisTitle, setXAxisTitle] = useState("")



    useEffect(() => {
        startUp()
    }, [config])

    useEffect(() => {
        deriveChartVarsFromChartCustomization()
    }, [chartConfiguration])

    const startUp = async () => {
        let validated = await validateAndSetConfig()
        if (validated) {
            let url = config.url
            let dataAPIResult = await getChartDataGenericCall(url, chartId, usercode, filters)
            setData(dataAPIResult.data)

        }
    }


    const validateAndSetConfig = async () => {
        if (!config) {
            setError(true)
            setErrorText('No configuration passed to BarSeries01')
            return false
        }
        let cfg = await JSON.parse(config.chartCustomization)
        setChartConfiguration(cfg)
        return true
    }

    // Used to 'skip' displaying X axis ticks 1 = no skipping, 2 = skip every other value, and so on
    let xAxisTickFormat = (t, i) => {
        if ((i + 1) % xAxisTickValue === 0) {
            return t.split(',')[0];
        } else {
            return;
        }
    }


    const deriveChartVarsFromChartCustomization = async () => {
        if (chartConfiguration) {
            // Derive chart customization variables from the userChart chartCustomization JSON
            if (chartConfiguration?.title) {
                setTitle(chartConfiguration.title.title)
            }
            if (chartConfiguration?.cardStyle) {
                setCardStyle(chartConfiguration.cardStyle)
            }
            if (chartConfiguration?.discreteColorLegend) {
                setLegend(chartConfiguration.discreteColorLegend)
            }
            if (chartConfiguration?.chartSize?.chartWidth) {
                setChartWidth(chartConfiguration.chartSize.chartWidth)
            }
            if (chartConfiguration?.chartSize?.chartHeight) {
                setChartHeight(chartConfiguration.chartSize.chartHeight)
            }
            if (chartConfiguration?.chartMargin) {
                setChartMargin(chartConfiguration.chartMargin)
            }
            if (chartConfiguration?.xAxisTickLabelAngle) {
                setXAxisTickLabelAngle(chartConfiguration.xAxisTickLabelAngle)
            }
            if (chartConfiguration?.yAxisTickLabelAngle) {
                setYAxisTickLabelAngle(chartConfiguration.yAxisTickLabelAngle)
            }
            if (chartConfiguration?.xAxisTickValue) {
                setXAxisTickValue(chartConfiguration.xAxisTickValue)
            }
            if (chartConfiguration?.yAxisTitle) {
                setYAxisTitle(chartConfiguration.yAxisTitle)
            }
            if (chartConfiguration?.xAxisTitle) {
                setXAxisTitle(chartConfiguration.xAxisTitle)
            }
        }
    }






    // const markSeries01Data = [
    //     { x: 1, y: 10, size: 1 },
    //     { x: 1.7, y: 12, size: 1 },
    //     { x: 2, y: 5, size: 1 },
    //     { x: 3, y: 15, size: 1 },
    //     { x: 2.5, y: 7, size: 1 }
    // ]


    if (data && chartConfiguration && !error) {
        return (
            <div className='card card-chart d-inline-block m-3 p-2' style={cardStyle}>
                <h5 className="card-title text-center">{title}</h5>
                {
                    config?.download && config?.download === "true" ?
                        <div className="d-flex flex-row-reverse"><DownloadToExcelIcon config={config} handleShow={handleShow} handleClose={handleClose} setSelectedChartIdDownload={setSelectedChartIdDownload} /></div>
                        : null
                }
                <div>
                    <XYPlot width={chartWidth} height={chartHeight}>
                        <VerticalGridLines />
                        <HorizontalGridLines />
                        <XAxis tickLabelAngle={xAxisTickLabelAngle} title={xAxisTitle} />
                        <YAxis tickLabelAngle={yAxisTickLabelAngle} title={yAxisTitle} />
                        <MarkSeries
                            className="mark-series-example"
                            strokeWidth={2}
                            opacity="0.8"
                            data={data}
                        />
                        {legend ? <DiscreteColorLegend
                            style={legend.style}
                            orientation={legend.orientation}
                            items={legend.items}
                        /> : null}
                    </XYPlot>
                </div>
            </div>
        )
    } else if (error) {
        return (
            <div className='card d-inline-block m-3 p-2'>
                <div className=' d-flex justify-content-center align-items-center'>
                    Error: {errorText}
                </div>
            </div>
        )
    } else {
        return (
            <div className='card d-inline-block m-3 p-2'>
                <div className=' d-flex justify-content-center align-items-center'>
                    <LoadingIcon />
                </div>
            </div>
        )
    }
}

export default MarkSeries01
