

export const getChartsGenericCall = async (subuserid) => {


    let firstTry = await APICall(10000)
    // console.log('getChartsGenericCall first', firstTry)
    if (Array.isArray(firstTry) || typeof firstTry === "object") {
        return firstTry
    } else {
        let secondTry = await APICall(10000)
        // console.log('getChartsGenericCall second', secondTry)
        if (Array.isArray(secondTry) || typeof secondTry === "object") {
            return secondTry
        } else {
            let thridTry = await APICall(10000)
            // console.log('getChartsGenericCall third', thridTry)
            if (Array.isArray(thridTry) || typeof thridTry === "object") {
                return thridTry
            } else {
                console.warn("getChartsGenericCall saw an api call fail 3x")
                return 'failed'
            }
        }
    }

    async function APICall(timeOutValue) {

        return new Promise(function (resolve, reject) {

            let controller = new AbortController();

            let url = "https://api.shippersedge.com/analyze/allcharts?subuserID=" + subuserid

            let myHeaders = new Headers();
            myHeaders.append("x-api-key", "vdhy8wwjUJrwhxFpfuECTiAW");

            var requestOptions = {
                method: "GET",
                headers: myHeaders,
                redirect: "follow",
            };

            let request = new Request(url, requestOptions);

            if (timeOutValue) {
                setTimeout(() => controller.abort(), timeOutValue);
            }


            fetch(request)
                .then((response) => {
                    // console.log(
                    //   "debug getChartsGenericCall.js response",
                    //   response
                    // );
                    if (!response.ok) {
                        console.error(
                            "getChartsGenericCall.js error",
                            response.statusText,
                            response
                        );
                        throw Error(response);
                    }
                    return response.json();
                })
                .then((response) => {
                    // console.log('getChartsGenericCall', response)
                    resolve(response);
                })

        });
    }
};

export default getChartsGenericCall