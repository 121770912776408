import { XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, MarkSeries, LineMarkSeries, DiscreteColorLegend, VerticalBarSeriesCanvas, } from 'react-vis';
import LoadingIcon from '../../../Components/LoadingIcon/LoadingIcon'

import React from 'react'

const ActualFreightRateToMatrixRateChart = ({ actualFreightToMatrixData }) => {

    const kissnerBlue = "#4f81bd"
    const kissnerRed = '#C0504D'
    const kissnerGreen = '#32cd32'

    let convertData = (actualFreightToMatrixData) => {
        let newArray = []
        for (let data of actualFreightToMatrixData) {
            // console.log('wut', Object.values(data)[0])
            let newObject = {}
            newObject.x = Object.keys(data)[0]
            newObject.y = Object.values(data)[0]
            newArray.push(newObject)

        }
        return newArray
    }


    let rightMargin = 50
    let chartWidth = 430

    let tickFormat = (t, i) => {
        if ((i + 1) % 2 === 0) {
            return t.split(',')[0];
        } else {
            return;
        }
    }

    let tickFormat2 = (t, i) => {
        return t + '%'
    }

    if (actualFreightToMatrixData) {
        let covertedData = convertData(actualFreightToMatrixData)

        return (
            <div className='card m-3 width500px'>
                <h5 className="card-title text-center">Actual Freight To Matrix Rate</h5>
                <XYPlot height={300} width={chartWidth} xType="ordinal" margin={{ bottom: 120 }}>
                    <VerticalGridLines />
                    <HorizontalGridLines />
                    <XAxis tickLabelAngle={-90} tickFormat={tickFormat} />
                    <YAxis tickFormat={tickFormat2} />
                    <LineSeries data={covertedData} />
                </XYPlot>
            </div>
        )
    } else {
        return (
            <div className='m-3 width500px d-flex justify-content-center align-items-center'>
                <LoadingIcon />
            </div>
        )
    }
}

export default ActualFreightRateToMatrixRateChart
