import React, { useState, useEffect } from 'react'
import { BarSeries01 } from '../../Customers/Kissner/Components/BarSeries01/BarSeries01'
import MarkSeries01 from '../../Customers/Kissner/Components/MarkSeries01/MarkSeries01'
import getChartsGenericCall from '../../Customers/Kissner/API/GenericAPICalls/getChartsGenericCall'
import RadialChart01 from '../../Customers/Kissner/Components/RadialChart01/RadialChart01'
import Doughnut01 from '../../Customers/Kissner/Components/Doughnut01/Doughnut01'
import BarSeries02 from '../../Customers/Kissner/Components/BarSeries02/BarSeries02'
import BarSeries03 from '../../Customers/Kissner/Components/BarSeries03/BarSeries03'
import BarSeries04 from '../../Customers/Kissner/Components/BarSeries04/BarSeries04'
import BarSeries05 from '../../Customers/Kissner/Components/BarSeries05/BarSeries05'
import BarSeries06 from '../../Customers/Kissner/Components/BarSeries06/BarSeries06'
import BarSeries07 from '../../Customers/Kissner/Components/BarSeries07/BarSeries07'
import BarSeries08 from '../../Customers/Kissner/Components/BarSeries08/BarSeries08'
import BarSeries09 from '../../Customers/Kissner/Components/BarSeries09/BarSeries09'
import BarAndLine01 from '../../Customers/Kissner/Components/BarAndLine01/BarAndLine01'
import BasicSeries from '../../Customers/Kissner/Components/BasicSeries/BasicSeries'
import BasicSeriesThree from '../../Customers/Kissner/Components/BasicSeries/BasicSeriesThree'
import BasicSeriesDollar from '../../Customers/Kissner/Components/BasicSeriesDollar/BasicSeriesDollar'
import Scatter01 from '../../Customers/Kissner/Components/Scatter01/Scatter01'
// import AreaSeries01 from '../../Customers/Kissner/Components/AreaSeries01/AreaSeries01'
import BigNumber01 from '../../Customers/Kissner/Components/BigNumber01/BigNumber01'
import LineSeries01 from '../../Customers/Kissner/Components/LineSeries01/LineSeries01'
import LineSeries02 from '../../Customers/Kissner/Components/LineSeries02/LineSeries02'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
// import Form from 'react-bootstrap/Form';
import FormControl from 'react-bootstrap/FormControl';

import submitEmailDataRequest from '../../Customers/Kissner/API/GenericAPICalls/submitEmailDataRequest'

const Dashboard = () => {

    let search = window.location.search
    let params = new URLSearchParams(search)
    let urlsubuserid = params.get('subuserid')
    let urlusercode = params.get('usercode')
    const [charts, setCharts] = useState([])
    const [filters, setFilters] = useState([])
    const [subuserId, setSubuserId] = useState(null)
    const [usercode, setUsercode] = useState(null)
    const [error, setError] = useState(false)
    const [errorTxt, setErrorTxt] = useState('')
    const [errorObj, setErrorObj] = useState('')
    const [selectedBrand, setSelectedBrand] = useState('All')
    const [selectedRegion, setSelectedRegion] = useState('All')
    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [selectedChartIdDownload, setSelectedChartIdDownload] = useState(null);

    const [modalSubmitText, setModalSubmitText] = useState('')
    const [modalSubmitAlertType, setModalSubmitAlertType] = useState('')
    const [disableEmailBtn, setDisableEmailBtn] = useState(true)

    const [downloadEmails, setDownloadEmails] = useState(null)
    const handleClose = () => {
        setModalSubmitText('')
        setModalSubmitAlertType('')
        setShowDownloadModal(false)
        setDisableEmailBtn(true)
        setSelectedChartIdDownload(null)
    };
    const handleShow = () => setShowDownloadModal(true);

    const handleRegionChange = ({ target: { value } }) => {
        setSelectedRegion(value);
        setCharts([]);
    console.log('line 66 value:', value)
        const handleAsyncOperation = async () => {
            if (value === 'All') {
              setFilters([]);
              getCharts();
            } else {
                setFilters([
                    {
                        field: 
                            "jobid"
                        ,
                        value
                    },
                ]);

                console.log('setfilters', setFilters)
                console.log('value', value)

                
    
                // Wait for setCharts([]) to finish before calling getCharts()
                await new Promise((resolve) => setTimeout(resolve, 0));
                setCharts([]);
                getCharts();
            }
        };
    
        // Invoke the async function immediately
        handleAsyncOperation();
    };
    
    

    const handleBrandChange = (event) => {
        setSelectedBrand(event.target.value);
        if (event.target.value === 'All') {
            setFilters([])
        } else {
            setFilters([{ field: `if(is_order='Y',(select fieldValue FROM masterquotes_customfields WHERE webquotecontrol=masterquotes.webquotecontrol and fieldID=10066),if((select count(*) FROM masterquotes_customfields WHERE masterquotes_customfields.webquotecontrol IN ( SELECT m.webquotecontrol FROM masterquotes m WHERE m.jobid is null and m.usercode='alanwire' and m.is_trip='N' and m.trip = masterquotes.webquotecontrol) and fieldID=10066 and fieldValue='${event.target.value}')>0,'${event.target.value}',null))`, value: event.target.value }])
        }
        setCharts([])
        getCharts()
    };

    useEffect(() => {
        startUp()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function startUp() {
        setSubuserId(urlsubuserid)
        setUsercode(urlusercode)
    }

    useEffect(() => {
        if (subuserId && usercode) {

            getCharts()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subuserId, usercode])

    async function getCharts() {
        let chartsAPIResult = await getChartsGenericCall(subuserId)
        // console.log('chartsAPIResult', chartsAPIResult)
        if (Array.isArray(chartsAPIResult) && chartsAPIResult !== 'failed') {
            let sortedCharts = chartsAPIResult.sort((a, b) => {
                // console.log('id?', a)
                if (a.chartOrder < b.chartOrder) {
                    return -1
                } else {
                    return 1
                }

            })
            setCharts(sortedCharts)
        } else {
            console.error('Dashboard.js chartsAPIResult failed', chartsAPIResult)
            setError(true)
            setErrorTxt('Charts Request Failed, please contact support.')
            if (chartsAPIResult) {
                setErrorObj(JSON.stringify(chartsAPIResult))
            }
        }
    }

    function modalEmailValidation(email) {
        if (/(.+)@(.+){2,}\.(.+){2,}/.test(email)) {
            setDisableEmailBtn(false)
        } else {
            setDisableEmailBtn(true)
        }
        setDownloadEmails(email)
    }


    if (charts.length && subuserId && usercode) {
        console.log('charts:', charts)
        return (
          <>
            <div className="mx-2 mt-1 mw-100" style={{ display: "flex" }}>
              {usercode === "alanwire" ? (
                <div
                  className="card mt-1"
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                    alignItems: "center",
                    justifyContent: "",
                    minWidth: "175px",
                  }}
                >
                  {/* Filters header */}
                  <h4>Filterable if * in chart title: </h4>
                  {/* Brand Filters */}
                  <label htmlFor="dropdown" style={{ marginRight: "10px" }}>
                    Brand:
                  </label>
                  <select
                    id="brand-dropdown"
                    value={selectedBrand}
                    onChange={handleBrandChange}
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <option value="All">All Brands</option>
                    <option value="Alan Wire">Alanwire</option>
                    <option value="Regency Wire">Regencywire</option>
                  </select>
                  {/* Mode Filter 
                        <label htmlFor="dropdown" style={{ marginRight: "10px", marginLeft: "10px" }}>
                            Mode:
                        </label>
                        <select
                            id="mode-dropdown"
                            value={selectedMode}
                            onChange={handleModeChange}
                            style={{ padding: "5px", borderRadius: "5px", border: "1px solid #ccc" }}
                            >
                            <option value="All">All Modes</option>
                            <option value="LTL">LTL</option>
                            <option value="TL">TL</option>
                        </select>*/}
                </div>
              ) : usercode === "halliburton" || usercode === "halliburton-dev" ? (
                // Dropdown for 'Halliburton'
                <div
                  class="card mt-1"
                  style={{
                    padding: "10px",
                    marginBottom: "10px",
                    backgroundColor: "#f5f5f5",
                    borderRadius: "5px",
                    alignItems: "center",
                    justifyContent: "",
                    minWidth: "175px",
                  }}
                >
                  {/* Filters header */}
                  <h4>Filterable if * in chart title: </h4>
                  {/* Region Filters for Halliburton */}
                  <label htmlFor="dropdown" style={{ marginRight: "10px" }}>
                    Region:
                  </label>
                  <select
                    id="region-dropdown"
                    value={selectedRegion}
                    onChange={handleRegionChange}
                    style={{
                      padding: "5px",
                      borderRadius: "5px",
                      border: "1px solid #ccc",
                    }}
                  >
                    <option value="All">All Regions</option>
                    <option value="2558">Northeast</option>
                    <option value="2563">Southeast</option>
                    <option value="2568">Permian</option>
                    <option value="2573">Gulf of Mexico</option>
                    <option value="2578">Midcon</option>
                    <option value="2583">Rockies</option>
                    {/* <option value="West Coast">West Coast</option>
                    <option value="Kilgore">Kilgore</option>
                    <option value="Victoria">Victoria</option>
                    <option value="Odessa">Odessa</option>
                    <option value="Gulf of Mexico">Gulf of Mexico</option>
                    <option value="Duncan">Duncan</option>
                    <option value="Ft. Lupton">Ft. Lupton</option>
                    <option value="Williston">Williston</option>
                    <option value="Casper">Casper</option>
                    <option value="Rock Springs">Rock Springs</option>
                    <option value="Vernal">Vernal</option>
                    <option value="Grand Junction">Grand Junction</option>
                    <option value="Farmington">Farmington</option>
                    <option value="Bakersfield">Bakersfield</option>
                    <option value="Brighton">Brighton</option> */}
                  </select>
                </div>
              ) : null}
              <div className="row d-flex ">
                <div className="col d-flex flex-wrap">
                  {charts.map((chart, index) => {
                    switch (chart.componentName) {
                      case "BasicSeries":
                        return (
                          <BasicSeries
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BasicSeriesDollar":
                        return (
                          <BasicSeriesDollar
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BasicSeriesThree":
                        return (
                          <BasicSeriesThree
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BigNumber01":
                        return (
                          <BigNumber01
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "Doughnut01":
                        return (
                          <Doughnut01
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries01":
                        return (
                          <BarSeries01
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries02":
                        return (
                          <BarSeries02
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries03":
                        return (
                          <BarSeries03
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries04":
                        return (
                          <BarSeries04
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries05":
                        return (
                          <BarSeries05
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries06":
                        return (
                          <BarSeries06
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries07":
                        return (
                          <BarSeries07
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries08":
                        return (
                          <BarSeries08
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarSeries09":
                        return (
                          <BarSeries09
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarAndLine01":
                        return (
                          <BarAndLine01
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "BarAndLine01":
                        return (
                          <MarkSeries01
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "RadialChart01":
                        return (
                          <RadialChart01
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "LineSeries01":
                        return (
                          <LineSeries01
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "LineSeries02":
                        return (
                          <LineSeries02
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );
                      case "Scatter01":
                        return (
                          <Scatter01
                            config={chart}
                            key={index}
                            chartId={chart.id}
                            subuserId={subuserId}
                            usercode={usercode}
                            filters={filters}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            setSelectedChartIdDownload={
                              setSelectedChartIdDownload
                            }
                          />
                        );

                      default:
                        return (
                          <div>
                            <div
                              className="alert alert-secondary m-3"
                              role="alert"
                            >
                              Unrecognized chart: {chart.componentName}, please
                              contact support.
                            </div>
                          </div>
                        );
                    }
                  })}
                </div>
              </div>

              <>
                <Modal show={showDownloadModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    {selectedChartIdDownload ? (
                      <Modal.Title>
                        Download Data for:
                        <br />
                        {
                          charts.filter(
                            (chart) => chart.id === selectedChartIdDownload
                          )[0].name
                        }
                      </Modal.Title>
                    ) : (
                      <Modal.Title>
                        Download Data for unknown chart.{" "}
                      </Modal.Title>
                    )}
                  </Modal.Header>
                  <Modal.Body>
                    {/* <input type="email" onChange={(event) => modalEmailValidation(event.target.value)}></input> */}
                    <div class="alert alert-light" role="alert">
                      An email will be sent with an Excel file attachment
                      containing data from this chart.
                    </div>
                    <InputGroup className="mb-3">
                      <FormControl
                        onChange={(event) =>
                          modalEmailValidation(event.target.value)
                        }
                        placeholder="Email"
                        aria-label="Email address field"
                      />
                      <Button
                        variant="primary"
                        onClick={() =>
                          submitEmailDataRequest(
                            selectedChartIdDownload,
                            downloadEmails,
                            usercode,
                            setModalSubmitText,
                            setModalSubmitAlertType
                          )
                        }
                        disabled={disableEmailBtn}
                      >
                        submit
                      </Button>
                    </InputGroup>

                    <div
                      className={`$ mt-3 p-3 ` + modalSubmitAlertType}
                      role="alert"
                    >
                      {modalSubmitText}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </div>
          </>
        );
    } else if (error) {
        return (<div className='m-3'>
            <div className="row">
                <div className="col">
                    <div className="alert alert-warning" role="alert">
                        Error: {errorTxt}
                        {errorObj ?
                            <>
                                <br /><br />
                                {errorObj}
                            </>
                            : null}
                    </div>
                </div>
            </div>
        </div>)
    } else {
        return (
            <div className='m-3'>
                <div className="row">
                    <div className="col">
                        LOADING
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard
