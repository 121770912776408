import React from "react";
import { Switch, Route } from "react-router-dom";

import KissnerDashboard from "../../Pages/Kissner/KissnerDashboard";
import Dashboard from "../../Pages/Dashboard/Dashboard";
import LandingPage from "../../Pages/LandingPage/LandingPage";

export default function Routes() {
    return (
        <Switch>
            <Route path="/dashboard" exact component={Dashboard} />
            <Route path="/kissner" exact component={KissnerDashboard} />
            <Route path="/" exact component={LandingPage} />
            <Route component={Dashboard} />
        </Switch>
    );
}
