import React from 'react'
import LoadingIcon from '../../../Components/LoadingIcon/LoadingIcon'

const TotalsByYearTable = ({ totalsByYearTableData }) => {

    if (totalsByYearTableData !== null) {
        return (
            <div className="width350px">
                <table className="table table-sm table-bordered x-small-font" >
                    <thead>
                        <tr className="text-center">
                            <th></th>
                            <th>FY21</th>
                            <th>FY20</th>
                            <th>FY19</th>
                            <th>FY18</th>
                            <th>FY17</th>
                        </tr>
                    </thead>
                    <tbody>

                        {totalsByYearTableData ?
                            Object.entries(totalsByYearTableData).map(([city, value]) => {
                                return (
                                    <tr>
                                        <td>{city}</td>
                                        <td>{value[21]}</td>
                                        <td>{value[20]}</td>
                                        <td>{value[19]}</td>
                                        <td>{value[18]}</td>
                                        <td>{value[17]}</td>
                                    </tr>
                                )
                            })

                            : null}


                    </tbody>
                </table >
            </div>


        )
    } else {
        return (<div className='m-3 width350px d-flex justify-content-center align-items-center'>
            <LoadingIcon />
        </div>)
    }
}

export default TotalsByYearTable
