import React, { useState, useEffect } from 'react'
import '../../../node_modules/react-vis/dist/style.css';
// import { XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, VerticalBarSeries, MarkSeries, LineMarkSeries, DiscreteColorLegend, VerticalBarSeriesCanvas } from 'react-vis';
import OrdersTodayTable from './Components/OrdersTodayTable'
import OrdersShippedPerDayChart from './Components/OrdersShippedPerDayChart'
import TotalsByYearTable from './Components/TotalsByYearTable'
import LaneCostsTable from './Components/LaneCostsTable'
import AverageFreightRateByWeekChart from './Components/AverageFreightRateByWeekChart'
import ActualFreightToMatrixRate from './Components/ActualFreightRateToMatrixRateChart'
import NonOptimalShipentsChart from './Components/NonOptimalShipmentsChart'
import NonOptimalShipmentsTable from './Components/NonOptimalShipmentsTable'
import useData from './hooks/useData'



const Kissner = () => {

    const { ordersTopTableData, ordersShippedPerDayData, totalsByYearTableData, laneCosts, rateByWeek, actualFreightToMatrixData, nonOptimalShipmentsData, nonOptimalShipmentsListData } = useData()

    const kissnerBlue = "#4f81bd"
    const kissnerRed = '#C0504D'
    const kissnerGreen = '#32cd32'

    let today = new Date()
    let yesterday = today.setDate(today.getDate() - 1);

    return (

        <div className='card niceBackground'>
            <div className="d-flex flex-row justify-content-between ">
                <div />
                <div >
                    <h2 className="text-center">Daily Shipping Report - {new Date(yesterday).toLocaleDateString()}</h2>
                </div>
                <div className="mt-2">
                    <button className="btn btn-outline-dark btn-sm" onClick={() => window.print()}>Print</button>
                </div>
            </div>
            <div className='card-body justify-content-between'>
                {/* Top Table */}
                <div className='row'>
                    <OrdersTodayTable ordersTopTableData={ordersTopTableData} />
                </div>
                {/* Totals by FY */}
                {/* Orders Shipped Per Day */}
                <div className='row'>
                    <TotalsByYearTable totalsByYearTableData={totalsByYearTableData} />
                    <OrdersShippedPerDayChart ordersShippedPerDayData={ordersShippedPerDayData} />
                </div>

                {/* Lane Costs */}
                {/* Actual Freight Rate to Matrix Rate */}
                <div className='row '>
                    <LaneCostsTable laneCosts={laneCosts} />
                    <ActualFreightToMatrixRate actualFreightToMatrixData={actualFreightToMatrixData} />

                </div>
                {/* Average Freight By Week / Non-Optimal Shipments */}
                <div className='row'>
                    <AverageFreightRateByWeekChart rateByWeek={rateByWeek} />
                    <NonOptimalShipentsChart nonOptimalShipmentsData={nonOptimalShipmentsData} />
                </div>
                <div className='row'>
                    <NonOptimalShipmentsTable nonOptimalShipmentsListData={nonOptimalShipmentsListData} />
                </div>
            </div>

        </div >
    )
}

export default Kissner
